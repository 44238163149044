import { IEvent } from '../types/event';

const dayInMS = 86400000;

/**
 * Calculates days in event group
 * @param events Array of event ranges
 * @param countWeekends Determines if weekends should be counted, defaults to true
 */
export const calcDays = (events: IEvent[], countWeekends: boolean = true): number => {
  let output = 0;
  events.forEach(event => {
    let rangeCount: number = Math.round((event.end.getTime() - event.start.getTime()) / dayInMS) + 1;
    if (!countWeekends) {
      let noWeekendsCount = 0;
      let i = 0;
      while(i < rangeCount) {
        const loopDate = new Date(
          event.start.getFullYear(),
          event.start.getMonth(),
          event.start.getDate() + i
        );
        if (loopDate.getDay() !== 0 && loopDate.getDay() !== 6) {
          noWeekendsCount++
        }
        i++;
      }
      rangeCount = noWeekendsCount;
    }
    output += rangeCount;
  }); 
  return output;
}
