import React, { createRef } from 'react';
import classnames from 'classnames';
import { IEventInputDialogProps, IEventInputDialogState } from './types';
import styles from './style.module.css';

import GroupIndicator from '../group-indicator';

export default class EventInputDialog extends React.Component<
  IEventInputDialogProps,
  IEventInputDialogState
> {
  state: IEventInputDialogState = {
    eventNameDialog: this.props.eventName
  };

  // Edit title input ref.
  inputToFocus = createRef<HTMLInputElement>();

  // Update props and focus input on component update/props change.
  componentDidUpdate(prevProps: IEventInputDialogProps) {
    const updatedProps = this.props;
    // Update props if changed.
    if (prevProps.eventName !== updatedProps.eventName) {
      this.setState({ eventNameDialog: this.props.eventName });
    }

    // AutoFocus input only when modal is opened.
    if (this.inputToFocus.current && !prevProps.showTitleDialog) {
      this.inputToFocus.current.focus();
    }
  }

  /**
   * Updates the event title and closes the modal.
   * @param e this event value
   */
  private saveEventTitle = () => {
    if (this.state.eventNameDialog) {
      // Update event title.
      this.props.saveEventTitle(
        this.state.eventNameDialog,
        this.props.currentActiveIndex
      );

      // Close modal.
      this.props.closeTitleDialogMobile();
    }
  };

  /**
   * Updates the event title state onChange.
   * @param e this event value
   */
  private updateEventTitleDialogName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ eventNameDialog: e.target.value });
  };

  /**
   * Updates the event title name onKeyUp ("Enter" keyboard event).
   * @param e this event value
   */
  private updateEventTitleDialogNameOnKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key && e.key === 'Enter') {
      this.saveEventTitle();
    }
  };

  private handleRequestRemove = () => {
    this.props.onRemove && this.props.onRemove(this.props.currentActiveIndex);
    this.props.closeTitleDialogMobile();
  }

  render() {
    return (
      <>
        <div
          className={classnames(
            styles.dialogBackdrop,
            this.props.showTitleDialog && styles.shown
          )}
          onClick={this.props.closeTitleDialogMobile}
        />
        <dialog
          open={this.props.showTitleDialog}
          className={styles.mobileTitleDialog}
        >
          <label className={styles.mobileTitleInputGroup}>
            <b>Rename date group</b>
            <button className={styles.deleteButton} onClick={this.handleRequestRemove}>Delete</button>
            <div className={styles.mobileTitleInputContainer}>
              <input
                ref={this.inputToFocus}
                type="text"
                value={this.state.eventNameDialog}
                onChange={this.updateEventTitleDialogName}
                onKeyUp={this.updateEventTitleDialogNameOnKeyUp}
              />
              <div className={styles.indicatorHolder}>
                <GroupIndicator active={true} color={this.props.eventColor} />
              </div>
            </div>
          </label>
          <button
            className={classnames(
              styles.mobileTitleSave,
              !this.state.eventNameDialog && styles.disableBtn
            )}
            onClick={this.saveEventTitle}
          >
            Save
          </button>
        </dialog>
      </>
    );
  }
}
