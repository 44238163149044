import { IEvent } from "../types/event";

const mergeRanges = (ranges: IEvent[]) => {
  const newRanges = ranges
    .sort(
      ({ start: aStart }, { start: bStart }) =>
        aStart.getTime() - bStart.getTime()
    )
    .reduce(
      (acc: IEvent[], range: IEvent) => {
        if (!acc[0]) {
          return [range];
        }
        const latestRange = acc.slice(-1)[0];
        // If days are within 25 hours of each other
        if (range.start.getTime() - latestRange.end.getTime() <= 9e+7) {
          return [
            ...acc.slice(0, -1),
            { start: latestRange.start, end: range.end }
          ];
        } else {
          return [...acc, range];
        }
      },
      [] as IEvent[]
    );
  return newRanges;
};

export default mergeRanges;
