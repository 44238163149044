import React, { Component } from 'react';
import { calcDays } from '../../utils/calc-days';
import splitWeekends from '../../utils/split-on-weekends';
import { months } from '../../constants/months-english';
import gaEvent from '../../utils/ga-events';
import ShareIcon from '../../assets/share-icon.svg';
import CloseIcon from '../../assets/close-icon.svg';
import styles from './style.module.css';
import { IShareProps, IShareState } from './types';

/**
 * The share module component.
 * Displays a variety of ways for the user to export their calendar
 */
export default class Share extends Component<IShareProps, IShareState> {
  /**
   *  Default component state
   */
  state: IShareState = {
    textCalendarCopied: false,
    urlCalendarCopied: false,
  };
  /**
   * Text Area input element
   */
  private urlTextArea: HTMLTextAreaElement | undefined;
  
  /**
   * Function to set the text area input element ref
   * @param el the text area element being set to the urlTextArea ref
   */
  private urlTextAreaRef = (el: HTMLTextAreaElement) => (this.urlTextArea = el);

  /**
   * Div input elmeent
   */
  private calendarTextArea: HTMLDivElement | undefined;

  /**
   * Function to set the calendar area input element ref
   */
  private calendarTextAreaRef = (el: HTMLDivElement) => (this.calendarTextArea = el);

  /**
   * Select share url text onFocus
   */
  private handleUrlFocus = () => {
    if (this.urlTextArea) {
      this.urlTextArea.select();
    }
  }

  /**
   * Copies the text from urlTextArea to the user's clipboard
   */
  private handleCopyUrl = () => {
    if (this.urlTextArea) {
      this.urlTextArea.select();
      document.execCommand('copy');
      this.setState(
        { urlCalendarCopied: true },
        () => setTimeout(
          () => this.setState({ urlCalendarCopied: false }),
          1200
        )
      );
      gaEvent({
        category: 'Share',
        action: 'click',
        label: 'Copy URL'
      });
    }
  }

  /**
   * Shares the page url using a share sheet, if available
   */
  private handleShare = () => {
    if ((navigator as any)?.share) {
      (navigator as any).share({
        title: this.props.calendarTitle,
        url: window.location.href
      });
      gaEvent({
        category: 'Share',
        action: 'click',
        label: 'Open Share Sheet'
      });
    }
  }

  /**
   * Select all calendar text in content div and returns selection
   */
  private handleDivFocus = () => {
    if (window.getSelection && this.calendarTextArea) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNode(this.calendarTextArea);
      if (selection !== null) {
        selection.removeAllRanges();
        selection.addRange(range);
        return selection;
      }
    }
    return null;
  }

  /**
   * Selects and copies the text in the calendar as text output div
   */
  private handleCopyCalendar = () => {
    const selection = this.handleDivFocus();
     if (selection !== null) {
       document.execCommand('copy');
       this.setState(
        { textCalendarCopied: true },
        () => setTimeout(
          () => this.setState({ textCalendarCopied: false }),
          1200
        )
      );
      gaEvent({
        category: 'Share',
        action: 'click',
        label: 'Copy Text'
      });
    }
  }

  /**
   * Disable editing url or share text
   */
  private disableEdits = (e: React.KeyboardEvent) => {
    e.preventDefault();
  }

  render() {
    return (
      <>
        <div
          className={styles.backdrop}
          onClick={this.props.onClose}
        />
        <div className={styles.container}>
          <div className={styles.modalContent}>
            <h1 className={styles.modalHeader}>Share this calendar</h1>
            <div className={styles.shareOptionsContainer}>
              <div className={styles.shareOption}>
                <textarea
                  ref={this.urlTextAreaRef}
                  className={styles.shareContent}
                  defaultValue={window.location.href}
                  onFocus={this.handleUrlFocus}
                  onKeyPress={this.disableEdits}
                />
                <div className={styles.shareActions}>
                  <button
                    disabled={this.state.urlCalendarCopied}
                    className={styles.primary}
                    onClick={this.handleCopyUrl}
                  >
                    {this.state.urlCalendarCopied ? 'Copied' : 'Copy'} link
                  </button>
                  {(navigator as any).share && (
                    <button
                      onClick={this.handleShare}
                      className={styles.secondary}
                    >
                      <img
                        alt='Open share sheet'
                        src={ShareIcon}
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className={styles.shareOption}>
                <div
                  className={styles.shareContent}
                  contentEditable
                  onFocus={this.handleDivFocus}
                  onKeyPress={this.disableEdits}
                >
                  <div ref={this.calendarTextAreaRef}>
                    {this.props.events.map((eventGroup, index) => {
                      const dayCount = calcDays(eventGroup.events, this.props.countWeekends);
                      const dayGroups = this.props.countWeekends
                        ? eventGroup.events
                        : splitWeekends(eventGroup.events);
                        return dayCount > 0 && (
                          <div key={index}>
                            <b>
                              {eventGroup.title} ({dayCount} {dayCount > 1 ? 'days' : 'day'})
                            </b>
                            <br />
                            {dayGroups.map((range, dayIndex) => (
                              <span
                                key={dayIndex}
                                style={{ display: 'block' }}
                              >
                                {`${months[range.start.getMonth()].long} ${
                                  range.start.getDate()
                                }, ${range.start.getFullYear()}${range.end > range.start ? ` - ${
                                  months[range.end.getMonth()].long
                                } ${range.end.getDate()}, ${ range.end.getFullYear()}` : ''}`}
                              </span>
                            ))}
                            {index + 1 < this.props.events.length && (
                              <>
                              <br />
                          </>
                          )}
                        </div>
                        );
                    })}
                  </div>
                </div>
                <div className={styles.shareActions}>
                  <button
                    disabled={this.state.textCalendarCopied}
                    onClick={this.handleCopyCalendar}
                    className={styles.primary}
                  >
                    {this.state.textCalendarCopied ? 'Copied' : 'Copy as'} text
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            className={styles.closeBtn}
            onClick={this.props.onClose}
          >
            <img
              alt='Close this modal'
              src={CloseIcon}
            />
          </button>
        </div>
      </>
    );
  }
}
