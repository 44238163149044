import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import isObject from 'lodash/isObject';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import { debounce } from 'debounce';
import * as Sentry from '@sentry/browser';

import Month from '../month';
import { calcDays } from '../../utils/calc-days';
import EventInput from '../event-input';
import EventInputDialog from '../event-input-dialog';
import CalendarController from '../calendar-controls';
import Settings from '../settings';
import Share from '../share';
import { serialize, deserialize } from '../../utils/url-serializer';
import gaEvent from '../../utils/ga-events';
import mergeRanges from '../../utils/merge-ranges';
import addIcon from '../../assets/plus-icon.svg';
import tinyMonthLogo from '../../assets/tiny-month-logo.svg';
import settingsDropdownIcon from '../../assets/dropdown-side.svg';
import mobileDropdownIcon from '../../assets/dropdown-mobile.svg';
import closeIconMobile from '../../assets/close-icon-mobile.svg';
import styles from './style.module.css';
import {
  ICalendarState,
  RangeMode,
  IHashURL,
  IAddGroupButtonProps
} from './types';
import { TEventDisplay } from '../day/types';
import { IEvent } from '../../types/event';
import { color } from '../../types/color';

const supportsSmoothScrolling = 'scrollBehavior' in document.documentElement.style;

const colorValues = [
  color.blue,
  color.orange,
  color.turquoise,
  color.purple,
  color.yellow
];

const defaultEvent = {
  title: 'Event Group 1',
  color: colorValues[0],
  events: []
};

const AddEventGroupButton = (props: IAddGroupButtonProps) => (
  <button className={styles.addEventGroupBtn} onClick={props.onClick}>
    <img alt="add another" src={addIcon} className={styles.plusIcon} />
    <span className={styles.addText}> Add new group</span>
  </button>
);

export default class Calendar extends Component<any, ICalendarState> {
  private inputToFocus = createRef<HTMLInputElement>();
  private monthsContainer = createRef<HTMLDivElement>();
  private monthsScrollView = createRef<HTMLDivElement>();
  state: ICalendarState = {
    name: '',
    activeEvent: 0,
    editableEvent: null,
    events: [defaultEvent],
    // Start Month defaults to current month if not in the hash
    startMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    // Range defaults to twelve
    range: 12,
    newEventRange: null,
    dragStartDate: null,
    rangeMode: RangeMode.ADD,
    showSettings: false,
    showEventGroupDropdown: false,
    showShare: false,
    showTitleDialog: false,
    settings: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      countWeekends: true,
      showWeekNumbers: false,
      indicateToday: true,
      displayType: TEventDisplay.Blocks
    }
  };

  /**
   * Restores the previous state from the URL query and hash
   */
  private updateStateFromURL = () => {
    if (window.location.search !== '') {
      const rawQueries = window.location.search;
      const queries = rawQueries
        .replace('?', '')
        .split(',')
        .map(protoQuery => {
          const queryParts = protoQuery.split('=');
          if (queryParts.length === 2) {
            return {
              [decodeURIComponent(queryParts[0])]: decodeURIComponent(
                queryParts[1]
              )
            };
          } else {
            return protoQuery;
          }
        });
      queries.forEach(query => {
        if (isObject(query) && typeof query.name === 'string') {
          this.setState({
            name: query.name
          });
        }
      });
    }

    if (window.location.hash !== '') {
      try {
        const hashObject = deserialize(
          atob(window.location.hash.replace('#', ''))
        ) as IHashURL;
        if (hashObject.events) {
          this.setState({
            events: hashObject.events
              ? hashObject.events.map(eventGroup => ({
                  color: colorValues[eventGroup.color],
                  title: eventGroup.title,
                  events: eventGroup.events.map(event => ({
                    start: new Date(event[0][0], event[0][1], event[0][2]),
                    end: new Date(event[1][0], event[1][1], event[1][2])
                  }))
                }))
              : []
          });
        }
        //update state, get first month in calendar from hashed url
        if (hashObject.startMonth) {
          const rawStartMonth = new Date(
            hashObject.startMonth[0],
            hashObject.startMonth[1],
            hashObject.startMonth[2]
          );
          this.setState({
            startMonth: hashObject.startMonth
              ? new Date(
                  rawStartMonth.getFullYear(),
                  rawStartMonth.getMonth(),
                  rawStartMonth.getDate()
                )
              : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              settings: {
                ...this.state.settings,
                month: rawStartMonth.getMonth() + 1,
                year: rawStartMonth.getFullYear(),
                countWeekends: hashObject.countWeekends,
                indicateToday: hashObject.indicateToday,
                displayType: hashObject.displayType
              }
              // Ensure that month updates first before updating scroll position
          }, this.scrollToCurrentMonth);
        } else {
          // Update scroll view to current month regardless
          this.debouncedScrollToCurrentMonth();
        }
      } catch(e) {
        Sentry.captureException(e);
      }
    } else {
      // Update scroll to current month regardless
      this.debouncedScrollToCurrentMonth();
    }
  };

  /**
   * Sets the first month displayed in the calendar
   * @param startMonth the first date of the month at midnight
   */
  private setStartMonth = async (startMonth: Date) =>
    await this.setState({
      startMonth,
      settings: {
        ...this.state.settings,
        month: startMonth.getMonth() + 1,
        year: startMonth.getFullYear()
      }
    }, this.updateHashUrl);

  private setStartMonthToCurrent = () =>
    this.setState({
      startMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      settings: {
        ...this.state.settings,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    }, this.scrollToCurrentMonth);

  /**
   * Sets the active event
   * @param activeEvent the zero-index position of the event you wish to set as active
   */
  private setActiveEvent = async (activeEvent: number) => {
    this.setState({
      activeEvent,
      editableEvent: this.state.activeEvent === activeEvent ? activeEvent : null
    });
  };

  /**
   * Sets the editable event
   * @param editableEvent the zero-index position of the event you wish to set as editable
   */
  private setEditableEvent = (editableEvent: number | null) =>
    this.setState({
      editableEvent
    });

  private setTitleDialogVisibillity = (value?: boolean) =>
    this.setState(
      {
        showTitleDialog:
          typeof value !== 'undefined' ? value : !this.state.showTitleDialog
      },
      () => {
        if (this.inputToFocus.current) {
          this.inputToFocus.current.focus();
        }
      }
    );

  private closeTitleDialog = () => this.setTitleDialogVisibillity(false);

  private setShowShareDialog = (value?: boolean) =>
    this.setState({
      showShare: typeof value !== 'undefined' ? value : !this.state.showShare
    });

  private openShareDialog = () => {
    this.setShowShareDialog(true);
    gaEvent({
      category: 'Sharing',
      action: 'click',
      label: 'Open Share Dialog',
    })
  };
  
  private closeShareDialog = () => {
    this.setShowShareDialog(false);
    gaEvent({
      category: 'Sharing',
      action: 'click',
      label: 'Close Share Dialog',
    });
  };

  private onEditTitle = (activeEvent: number, isMobile: boolean) => {
    if (isMobile) {
      this.setActiveEvent(activeEvent);
      this.setTitleDialogVisibillity(true);
    } else {
      this.setEditableEvent(activeEvent);
    }
  };

  private onTabClickNavigation = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (index < this.state.events.length) {
      event.preventDefault();
      this.setActiveEvent(index);
      this.onEditTitle && this.onEditTitle(index, this.props.mobile!!);
    }
  };

  private onKeyDowncalendatTitle = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === 'Tab') {
      this.onTabClickNavigation(0, event);
    }
  };
  /**
   * Toggles settings visibility
   */
  private toggleSettingsVisibility = () =>
    this.setState({ showSettings: !this.state.showSettings });

  /**
   * Updates the title of an event group
   * @param title The event group name
   * @param index The z-index of the event group whose title you're changing
   */
  private updateGroupTitle = (title: string, index: number) => {
    const newEvents = this.state.events;
    newEvents[index].title = title;
    this.setState({ events: newEvents }, this.updateHashUrl);
  };

  /**
   * Changes the months shown in view
   * @param range number of months shown in viewport
   */
  setRange = (range: number) => this.setState({ range });

  private toggleEventGroupDropdown = () =>
    this.setState({
      showEventGroupDropdown: !this.state.showEventGroupDropdown
    });

  /**
   * Creates base64 encoded JSON string of elements of the current state
   */
  private genUrlObject = () => {
    const { events, startMonth } = this.state;
    const urlObject: IHashURL = {
      startMonth: [
        startMonth.getFullYear(),
        startMonth.getMonth(),
        startMonth.getDate()
      ],
      events: events.map(group => ({
        color: colorValues.indexOf(group.color),
        title: group.title,
        events: group.events.map(event => [
          [
            event.start.getFullYear(),
            event.start.getMonth(),
            event.start.getDate()
          ],
          [event.end.getFullYear(), event.end.getMonth(), event.end.getDate()]
        ])
      })),
      countWeekends: this.state.settings.countWeekends,
      indicateToday: this.state.settings.indicateToday,
      displayType: this.state.settings.displayType
    };
    const serializedString = serialize(urlObject);
    const baseString = Buffer.from(serializedString).toString('base64');
    return baseString;
  };

  /**
   * Iterates up to the next viewport
   */
  private onIterateMonthsUp = () => {
    if (this.monthsContainer.current && this.monthsScrollView.current) {
      const { startMonth } = this.state;
      const shadowMonthCount = this.genShadowMonthsCounter();
      const newStartMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() + shadowMonthCount);
      const setNewMonth = () => this.setStartMonth(newStartMonth).then(this.scrollToCurrentMonth);
      
      
      // If browser supports smooth scrolling animate transition
      if (supportsSmoothScrolling) {
        const target = this.monthsContainer.current.children[shadowMonthCount * 2];
        const afterScroll = debounce(() => {
          if (this.monthsScrollView.current) {
            this.monthsScrollView.current.removeEventListener('scroll', afterScroll);
            setNewMonth()
          }
        }, 50);

        this.monthsScrollView.current.addEventListener('scroll', afterScroll);
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // The browser doesn't support smooth scrolling, just set new month
      } else {
        setNewMonth();
      }
    }
  };

  /**
   * Iterates down to the prior viewport
   */
  private onIterateMonthsDown = () => {
    if (this.monthsContainer.current && this.monthsScrollView.current) {
      const { startMonth } = this.state;
      const shadowMonthCount = this.genShadowMonthsCounter();
      const newStartMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() - shadowMonthCount);
      const setNewMonth = () => this.setStartMonth(newStartMonth).then(this.scrollToCurrentMonth);
      // If browser supports smooth scrolling, transition to new month
      if (supportsSmoothScrolling) {
        // The next target is always going to be the first child
        const target = this.monthsContainer.current.children[0];

        const afterScroll = debounce(() => {
          if (this.monthsScrollView.current) {
            this.monthsScrollView.current.removeEventListener('scroll', afterScroll);
            setNewMonth();
          }
        }, 50);

        this.monthsScrollView.current.addEventListener('scroll', afterScroll);
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // The browser doens't support smooth scrolling, set new month without transition
      } else {
        setNewMonth();
      }
    }
  };

  /**
   * Creates a new event group
   */
  private onAddEventGroup = () => {
    const index = this.state.events.length;
    const usedColors = this.state.events.map(event => event.color);
    const availableColors = difference(colorValues, usedColors);
    this.setState(
      {
        events: [
          ...this.state.events,
          {
            title: `Event Group ${index + 1}`,
            color: availableColors[0] || colorValues[0],
            events: []
          }
        ],
        activeEvent: index,
        editableEvent: index
      },
      this.updateHashUrl
    );
  };

  /**
   * Removes an event group
   * @param index Index of the event to be removed
   */
  private onRemoveEventGroup = (index: number) => {
    const eventsLength = this.state.events.length;
    this.setState({ editableEvent: null }, () => {
      if (eventsLength === 1) {
        this.setState({ events: [defaultEvent] }, this.updateHashUrl);
      } else {
        const newEvents = filter(this.state.events, (_, i) => i !== index);

        this.setState(
          { events: newEvents, activeEvent: index ? index - 1 : 0 },
          this.updateHashUrl
        );
      }
    });
  };

  /**
   * Updatest the calendar title
   * @param e Change event from the text input
   */
  private onCalendarTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        name: e.target.value
      },
      this.updateHashUrl
    );
    document.title =
      e.target.value && e.target.value !== ''
        ? `${e.target.value} - TinyMonth`
        : 'TinyMonth';
  };

  /**
   * Responds to a mouse down event on the calendar
   * @param date date object of where a drag started
   */
  private handleStartDate = (date: Date) => {
    if (window.PointerEvent) {
      window.addEventListener('pointerup', this.handleRangeEnd);
    } else {
      window.addEventListener('mouseup', this.handleRangeEnd);
    }
    let rangeMode: RangeMode = RangeMode.ADD;
    const currentEvent = this.state.events[this.state.activeEvent];
    for (let i = 0; i < currentEvent.events.length; i++) {
      const event = currentEvent.events[i];
      if (event.start <= date && event.end >= date) {
        rangeMode = RangeMode.SUBTRACT;
        break;
      }
    }
    this.setState({
      newEventRange: {
        start: date,
        end: date
      },
      dragStartDate: date,
      rangeMode
    });
  };

  /**
   * Responds to a mouse over event on dates after a drag has started
   */
  private handleDragDate = (date: Date) => {
    const { dragStartDate } = this.state;
    if (dragStartDate !== null) {
      const rangeAfter = date > dragStartDate;
      const updatedEventRange: IEvent = rangeAfter
        ? {
            start: dragStartDate,
            end: date
          }
        : {
            start: date,
            end: dragStartDate
          };
      this.setState({ newEventRange: updatedEventRange });
    }
  };

  /**
   * Respondes to a mouse up event after a user has started adding a new event range
   */
  private handleRangeEnd = () => {
    if (window.PointerEvent) {
      window.removeEventListener('pointerup', this.handleRangeEnd);
    } else {
      window.removeEventListener('mouseup', this.handleRangeEnd);
    }
    const { newEventRange, activeEvent, events, rangeMode } = this.state;
    if (newEventRange !== null) {
      let inRange: boolean = false;
      const updatedEvents: (IEvent | undefined)[] = [
        ...events[activeEvent].events
      ];
      const loopLenth = updatedEvents.length;
      for (let i = 0; i < loopLenth; i++) {
        const event = updatedEvents[i] as IEvent;
        inRange =
          (event.start <= newEventRange.start &&
            newEventRange.start <= event.end) ||
          (event.start <= newEventRange.end &&
            newEventRange.end <= event.end) ||
          (newEventRange.start < event.start && event.end < newEventRange.end);
        if (inRange) {
          if (rangeMode === RangeMode.ADD) {
            // Amend event to add range
            updatedEvents[i] = {
              start:
                event.start < newEventRange.start
                  ? event.start
                  : newEventRange.start,
              end: event.end > newEventRange.end ? event.end : newEventRange.end
            };
          } else {
            // Amend event to remove range
            const entirelyInRange =
              event.start <= newEventRange.start &&
              event.end >= newEventRange.end;
            if (entirelyInRange) {
              const splitEventRange: IEvent = {
                start: new Date(
                  newEventRange.end.getFullYear(),
                  newEventRange.end.getMonth(),
                  newEventRange.end.getDate() + 1
                ),
                end: event.end
              };
              (updatedEvents[i] as IEvent).end = new Date(
                newEventRange.start.getFullYear(),
                newEventRange.start.getMonth(),
                newEventRange.start.getDate() - 1
              );
              if (splitEventRange.start <= splitEventRange.end) {
                updatedEvents.push(splitEventRange);
              }
            } else {
              if (event.start >= newEventRange.start) {
                (updatedEvents[i] as IEvent).start = new Date(
                  newEventRange.end.getFullYear(),
                  newEventRange.end.getMonth(),
                  newEventRange.end.getDate() + 1
                );
              } else if (event.end <= newEventRange.end) {
                (updatedEvents[i] as IEvent).end = new Date(
                  newEventRange.start.getFullYear(),
                  newEventRange.start.getMonth(),
                  newEventRange.start.getDate() - 1
                );
              }
              if (
                (updatedEvents[i] as IEvent).start >
                (updatedEvents[i] as IEvent).end
              ) {
                updatedEvents[i] = undefined;
              }
            }
          }
        }
      }

      const overlappedEvents = filter(updatedEvents, event => {
        if (event) {
          return (
            (event as IEvent).start <= newEventRange.start &&
            newEventRange.start <= (event as IEvent).end
          );
        }
      });
      const isOverlapping =
        rangeMode === RangeMode.SUBTRACT &&
        (newEventRange.start === newEventRange.end ||
          overlappedEvents.length === 0);

      if (!inRange && !isOverlapping) {
        updatedEvents.push(newEventRange);
      }
      const outputEvents = updatedEvents.filter(
        event => event !== undefined && event.end >= event.start
      ) as IEvent[];
      const newEvents = [...events];
      newEvents[activeEvent].events = mergeRanges(outputEvents);
      this.setState(
        {
          newEventRange: null,
          dragStartDate: null,
          events: newEvents
        },
        this.updateHashUrl
      );
    }
  };

  /**
   * Updates the count weekend setting
   * @param countWeekends the new boolean value for the setting
   */
  private setCountWeekends = (countWeekends: boolean) =>
    this.setState(
      {
        settings: {
          ...this.state.settings,
          countWeekends
        }
      },
      this.updateHashUrl
    );

  /**
   * Updates the show week number setting
   * @param showWeekNumbers the new boolean value for the setting
   */
  private setShowWeekNumbers = (showWeekNumbers: boolean) =>
    this.setState({
      settings: {
        ...this.state.settings,
        showWeekNumbers
      }
    });

  /**
   * Updates the value of the indicate today setting
   * @param indicateToday the new boolean value for the setting
   */
  private setIndicateToday = (indicateToday: boolean) =>
    this.setState(
      {
        settings: {
          ...this.state.settings,
          indicateToday
        }
      },
      this.updateHashUrl
    );

  /**
   * Updates the value of dispay setting
   * @param displayType the new `TEventDisplay` value for the setting
   */
  private setDisplayType = (displayType: TEventDisplay) =>
    this.setState(
      {
        settings: {
          ...this.state.settings,
          displayType
        }
      },
      this.updateHashUrl
    );

  /**
   * Display the selected month and year
   * @param month
   * @param year
   */
  private displayMonthYear = (month: number, year: number) => {
    const date = new Date();
    date.setMonth(month > 12 || month < 1 ? new Date().getMonth() : month - 1);
    date.setFullYear(
      year.toString().length < 4 ? new Date().getFullYear() : year
    );
    this.setStartMonth(date);
  };

  /**
   * Updates the month in the settings
   * @param month The month value
   */
  private setMonth = (month: string) => {
    this.setState({
      settings: {
        ...this.state.settings,
        month: month === '' ? null : Number(month)
      }
    });
  };

  /**
   * Updates the year in the settings
   * @param year The year value
   */
  private setYear = (year: number) => {
    this.setState({
      settings: {
        ...this.state.settings,
        year
      }
    });
  };

  private handleStartDrag = (e: PointerEvent | MouseEvent) => {
    const { target } = e;
    if (
      target !== null &&
      target instanceof HTMLDivElement &&
      target.classList.contains('date-node') &&
      !target.classList.contains('out-of-month')
    ) {
      e.preventDefault();
      const { date } = target.dataset;
      if (date) {
        const processedDate = new Date(date);
        this.handleStartDate(processedDate);
      }
    }
  };

  private handleOnDrag = (e: PointerEvent | MouseEvent) => {
    let { target } = e;
    if (e instanceof PointerEvent && e.pointerType !== 'mouse') {
      const trueTarget = document.elementFromPoint(e.clientX, e.clientY);
      if (trueTarget) {
        target = trueTarget;
      }
    }
    if (
      target !== null &&
      target instanceof HTMLDivElement &&
      target.classList.contains('date-node') &&
      !target.classList.contains('out-of-month')
    ) {
      e.preventDefault();
      const { date } = target.dataset;
      if (date) {
        const processedDate = new Date(date);
        this.handleDragDate(processedDate);
      }
    }
  };

  /**
   * Updates the hash URL with selected data
   */
  private updateHashUrl = debounce(() => {
    const queryString =
      this.state.name !== ''
        ? `?name=${encodeURIComponent(this.state.name)}`
        : '';
    const hashString = `#${this.genUrlObject()}`;
    if (
      window.location.hash !== hashString ||
      window.location.search !== queryString
    ) {
      if (window.history.replaceState) {
        window.history.replaceState('', '', queryString + hashString);
      } else {
        window.location.hash = hashString;
        window.location.search = queryString;
      }
    }
  }, 500);

  /**
   * Truncate strings according to a set limit and related total days.
   */
  private truncateStr = (str: string, limit: number, totalDays: 0 | number) => {
    //Get sum of digits of provided total days.
    let totalDaysDigits = Number(totalDays.toString().length);

    //Deduct total days digit (if multiple) from the original limit (for the string to fit).
    if (totalDaysDigits >= 2) {
      //Deduct original character
      totalDaysDigits = totalDaysDigits + 2;

      limit -= totalDaysDigits;
    }

    //Check if the string will fit.
    if (str.length <= limit) {
      return str;
    }

    //Deduct dots from string
    limit -= 3;
    return str.slice(0, limit) + '...';
  };

  private onKeyUpcalendatTitle = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  private genShadowMonthsCounter = () => {
    const isLandscape = window.innerWidth / window.innerHeight > 13 / 9;
    const shadowMonthCount = (isLandscape && window.innerWidth <= 1000) ||
      (!isLandscape && window.innerWidth > 620)
        ? 3
        : (!isLandscape && window.innerWidth <= 620)
          ? 1
          : 4;
    return shadowMonthCount;
  }

  private scrollToCurrentMonth = () => {
    if (this.monthsContainer.current) {
      const target = this.monthsContainer.current.children[this.genShadowMonthsCounter()];
      if (target) {
        target.scrollIntoView({ block: 'start' });
      }
    }
  }

  private debouncedScrollToCurrentMonth = debounce(this.scrollToCurrentMonth, 10);

  private get Months(): JSX.Element[] {
    const {
      range,
      startMonth,
      settings
    } = this.state;

    const { showWeekNumbers, indicateToday, countWeekends} = settings;
    const months: JSX.Element[] = [];
    const shadowMonthCount = this.genShadowMonthsCounter();
    
    for (let i = -1 * shadowMonthCount; i < range + shadowMonthCount; i++) {
      const monthDate = new Date(
        startMonth.getFullYear(),
        startMonth.getMonth() + i,
        1
      );
      months.push(
        <Month
          key={monthDate.getTime()}
          events={this.state.events}
          newEventRange={
            this.state.newEventRange !== null
              ? this.state.newEventRange
              : undefined
          }
          showYear={monthDate.getMonth() === 0 || i === 0}
          year={monthDate.getFullYear()}
          month={monthDate.getMonth()}
          showWeekNumbers={showWeekNumbers}
          indicateToday={indicateToday}
          countWeekends={countWeekends}
          displayType={this.state.settings.displayType}
        />
      );
    }

    return months;
  }

  componentDidMount() {
    this.updateStateFromURL();
    if (window.PointerEvent) {
      window.addEventListener('pointerdown', this.handleStartDrag);
      window.addEventListener('pointermove', this.handleOnDrag);
    } else {
      window.addEventListener('mousedown', this.handleStartDrag);
      window.addEventListener('mouseenter', this.handleOnDrag);
    }
  }

  componentWillUnmount() {
    if (window.PointerEvent) {
      window.removeEventListener('pointerdown', this.handleStartDrag);
      window.removeEventListener('pointermove', this.handleOnDrag);
    } else {
      window.removeEventListener('mousedown', this.handleStartDrag);
      window.removeEventListener('mouseenter', this.handleOnDrag);
    }
  }

  render() {
    const {
      activeEvent,
      showSettings,
      settings,
      editableEvent
    } = this.state;
    const { countWeekends, indicateToday } = settings;
    return (
      <div className={styles.appView}>
        <div className={styles.left}>
          <div className={styles.landscapeOnly}>
            <h1 className={styles.productTitle}>
              <img alt="TinyMonth" src={tinyMonthLogo} />
            </h1>
            <input
              className={styles.calendarTitle}
              placeholder="My Calendar"
              onChange={this.onCalendarTitle}
              onKeyUp={this.onKeyUpcalendatTitle}
              value={this.state.name}
              onKeyDown={this.onKeyDowncalendatTitle}
            />
            <div className={styles.eventGroupList}>
              {this.state.events.map((event, index) => (
                <EventInput
                  key={`event-group-${index}`}
                  index={index}
                  event={event}
                  countWeekends={this.state.settings.countWeekends}
                  activeEvent={activeEvent === index}
                  setActive={this.setActiveEvent}
                  exitEditMode={() => this.setEditableEvent(null)}
                  onTitleChange={this.updateGroupTitle}
                  editMode={editableEvent === index}
                  onRequestEdit={this.onEditTitle}
                  onRemove={this.onRemoveEventGroup}
                  onTabClickNavigation={this.onTabClickNavigation}
                />
              ))}
              {this.state.events.length < 5 && (
                <AddEventGroupButton onClick={this.onAddEventGroup} />
              )}
            </div>
            <div className={styles.optionsGroup}>
              <button
                className={classnames(
                  styles.settingsToggle,
                  showSettings && styles.open
                )}
                onClick={this.toggleSettingsVisibility}
              >
                <img
                  aria-hidden
                  alt=""
                  src={settingsDropdownIcon}
                  className={styles.caret}
                />
                <span className={styles.settingsText}>Calendar Options</span>
              </button>
              {showSettings && (
                <Settings
                  countWeekends={countWeekends}
                  indicateToday={indicateToday}
                  displayType={this.state.settings.displayType}
                  displayMonthYear={this.displayMonthYear}
                  onSetCountWeekends={this.setCountWeekends}
                  onSetIndicateToday={this.setIndicateToday}
                  onSetDisplayType={this.setDisplayType}
                  onSetMonth={this.setMonth}
                  onSetYear={this.setYear}
                  month={this.state.settings.month}
                  year={this.state.settings.year}
                />
              )}
              <button
                onClick={this.openShareDialog}
                className={styles.shareBtn}
              >
                Share Calendar
              </button>
            </div>
          </div>
          <div className={styles.portraitOnly}>
            <div
              className={classnames(
                styles.mobileHeaderRow,
                styles.firstMobileRow
              )}
            >
              <h1 className={styles.productTitle}>
                <img alt="TinyMonth" src={tinyMonthLogo} />
              </h1>
              {this.state.showShare ? (
                <button
                  onClick={this.closeShareDialog}
                  className={styles.closeShareBtn}
                >
                  Back to calendar
                  <img
                    alt=""
                    src={closeIconMobile}
                    className={styles.closeIcon}
                  />
                </button>
              ) : (
                <button
                  onClick={this.openShareDialog}
                  className={styles.shareBtn}
                >
                  Share Calendar
                </button>
              )}
            </div>
            <div
              className={classnames(styles.mobileHeaderRow, styles.splitView)}
            >
              <input
                className={styles.calendarTitle}
                placeholder="My Calendar"
                onChange={this.onCalendarTitle}
                onKeyUp={this.onKeyUpcalendatTitle}
                value={this.state.name}
                onKeyDown={this.onKeyDowncalendatTitle}
              />
              <button
                className={styles.mobileGroupToggle}
                onClick={this.toggleEventGroupDropdown}
              >
                <div className={styles.mobileToggleInner}>
                  <span
                    className={styles.groupIndicator}
                    style={{
                      backgroundColor: this.state.events[activeEvent].color
                    }}
                  />
                  <b>
                    {this.truncateStr(
                      this.state.events[activeEvent].title,
                      21,
                      calcDays(this.state.events[activeEvent].events)
                    )}
                  </b>
                </div>
                <img
                  aria-hidden
                  alt=""
                  src={mobileDropdownIcon}
                  className={styles.mobileToggleIcon}
                />
              </button>
            </div>
            {this.state.showEventGroupDropdown && (
              <div className={styles.mobileEventGroupList}>
                {/* TODO: Need to add numDays or similar to show accurate daycount */}
                {this.state.events.map((eventGroup, index) => (
                  <EventInput
                    key={`event-group-${index}`}
                    index={index}
                    event={eventGroup}
                    countWeekends={this.state.settings.countWeekends}
                    activeEvent={activeEvent === index}
                    setActive={this.setActiveEvent}
                    onRequestEdit={this.onEditTitle}
                    onTitleChange={this.updateGroupTitle}
                    mobile
                    editMode={false}
                    onRemove={this.onRemoveEventGroup}
                    exitEditMode={() => this.setEditableEvent(null)}
                    onTabClickNavigation={this.onTabClickNavigation}
                  />
                ))}
                {this.state.events.length < 5 && (
                  <AddEventGroupButton onClick={this.onAddEventGroup} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.calendarView}>
          <div
            ref={this.monthsScrollView}
            className={styles.scrollView}
          >
            <div
              ref={this.monthsContainer}
              className={styles.monthContainer}
            >
              {this.Months}
            </div>
          </div>
          <div>
            {showSettings && (
              <div className={styles.mobileSettingsContainer}>
                <Settings
                  countWeekends={countWeekends}
                  indicateToday={indicateToday}
                  displayType={this.state.settings.displayType}
                  displayMonthYear={this.displayMonthYear}
                  onSetCountWeekends={this.setCountWeekends}
                  onSetIndicateToday={this.setIndicateToday}
                  onSetDisplayType={this.setDisplayType}
                  onSetMonth={this.setMonth}
                  onSetYear={this.setYear}
                  month={this.state.settings.month}
                  year={this.state.settings.year}
                  twoColumnLayout
                />
              </div>
            )}
            <CalendarController
              settingsActive={this.state.showSettings}
              onToggleSettings={this.toggleSettingsVisibility}
              onIterateUp={this.onIterateMonthsUp}
              onIterateDown={this.onIterateMonthsDown}
              onCurrentMonth={this.setStartMonthToCurrent}
            />
          </div>
        </div>
        <EventInputDialog
          eventName={this.state.events[this.state.activeEvent].title}
          eventColor={this.state.events[this.state.activeEvent].color}
          showTitleDialog={this.state.showTitleDialog}
          closeTitleDialogMobile={this.closeTitleDialog}
          currentActiveIndex={this.state.activeEvent}
          saveEventTitle={this.updateGroupTitle}
          onRemove={this.onRemoveEventGroup}
        />
        {this.state.showShare && (
          <Share
            calendarTitle={this.state.name !== '' ? this.state.name : undefined}
            events={this.state.events}
            onClose={this.closeShareDialog}
            countWeekends={this.state.settings.countWeekends}
          />
        )}
      </div>
    );
  }
}
