import React from 'react';
import classnames from 'classnames';
import gaEvent from '../../utils/ga-events';
import dateDownIcon from '../../assets/date-down.svg';
import dateUpIcon from '../../assets/date-up.svg';
import todayIcon from '../../assets/today.svg';
import settingsIcon from '../../assets/settings-icon.svg'
import settingsIconActive from '../../assets/settings-icon-active.svg';
import postlightLogo from '../../assets/postlight-logo.svg';
import styles from './style.module.css';
import { ICalendarControlProps } from './types';

const onLogoClick = () => gaEvent({
  category: 'Outbound Link',
  action: 'click',
  label: 'Postlight Logo'
});

const CalendarControls = (props: ICalendarControlProps) => (
  <div className={styles.viewController}>
    <div className={styles.controls}>
      <button
        className={styles.yearController}
        onClick={props.onIterateDown}
        data-tooltip={'Scroll up'}
      >
        <img
          alt='Go to previous row of months'
          src={dateUpIcon}
        />
      </button>
      <button
        className={styles.yearController}
        onClick={props.onCurrentMonth}
        data-tooltip={'Go to current month'}
      >
        <img
          alt='Go to current month'
          src={todayIcon}
        />
      </button>
      <button
        className={styles.yearController}
        onClick={props.onIterateUp}
        data-tooltip={'Scroll down'}
      >
        <img
          alt='Go to next row of months'
          src={dateDownIcon}
        />
      </button>
      <button
        className={classnames(styles.yearController, styles.mobileOnly)}
        onClick={props.onToggleSettings}
        data-tooltip={props.settingsActive ? 'Hide settings' : 'Show settings'}
      >
        <img
          alt={props.settingsActive ? 'Hide settings' : 'Show settings'}
          src={props.settingsActive ? settingsIconActive : settingsIcon}
        />
      </button>
    </div>
    <div className={styles.branding}>
      <span className={styles.labsText}>
        A Labs project from your friends at
      </span>
      <a
        onClick={onLogoClick}
        target='_blank'
        rel='noopener noreferrer nofollow'
        href='https://www.postlight.com/careers'
      >
        <img
          className={styles.postlightLogo}
          alt='Postlight'
          src={postlightLogo}
        />
      </a>
    </div>
  </div>
);

export default CalendarControls;