import React from 'react';
import classnames from 'classnames';
import { IGroupIndicatorProps } from './types';
import styles from './styles.module.css';

const GroupIndicator = (props: IGroupIndicatorProps) => (
  <span
    className={classnames(
      styles.groupIndicator,
      props.active && styles.active
    )}
    style={{ backgroundColor: props.color }}
  />
);

export default GroupIndicator;
