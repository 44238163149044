import React from 'react';
import classnames from 'classnames';
import color from 'color';
import {
  IDateProps,
  IWeekMarkerProps,
  ILineMarkerProps,
  IBlockMarkerProps,
  TEventDisplay
} from './types';
import { color as TColor } from '../../types/color';
import { IEventGroup } from '../../types/event';
import styles from './style.module.css';

const dayClass = [
  styles.sunday,
  styles.monday,
  styles.tuesday,
  styles.wednesday,
  styles.thursday,
  styles.friday,
  styles.saturday
];

const isToday = (date: Date) => {
  const today = new Date();
  return today.getDate() === date.getDate()
    && today.getMonth() === date.getMonth()
    && today.getFullYear() === date.getFullYear();
}

const getGroupColor = (groups: IEventGroup[]) => {
  let output = color(groups[0].color);
  for (let i = 1; i < groups.length; i++) {
    output = output.mix(color(groups[i].color));
  }
  return output.hex();
}

export const Day = (props: IDateProps) => {
  const displayType = props.displayType || TEventDisplay.Blocks;
  const today = isToday(props.date);
  const outsideMonth = props.outsideMonth || false;
  const markToday = props.markToday !== undefined
    ? props.markToday
    : true;
  const countWeekends = props.countWeekends !== undefined
    ? props.countWeekends
    : false;
  const groupColor = props.selectedGroups && props.selectedGroups.length > 0
    ? getGroupColor(props.selectedGroups)
    : undefined;
  return (
    <div
      className={classnames(
        'date-node',
        styles.date,
        dayClass[props.date.getDay()],
        today && markToday && styles.today,
        outsideMonth && `out-of-month ${styles.dimmed}`,
        props.inSelection && styles.inRange,
        (displayType === TEventDisplay.Blocks && groupColor) && styles.strike,
        (displayType === TEventDisplay.Blocks && groupColor && color(groupColor).isDark())
          && styles.dark,
        ((props.date.getDay() === 6 || props.date.getDay() === 0 )&& !countWeekends)
          && styles.unchecked
      )}
      data-date={props.date}
    >
      {displayType === TEventDisplay.Lines && (
        <LineMarkers groups={props.selectedGroups} date={props.date} />
      )}
      {displayType === TEventDisplay.Blocks && (
        <>
          <BlockMarkers groups={props.selectedGroups} date={props.date}/>
          {props.inSelection && (
            <div className={styles.selectionIndicator} />
          )}
        </>
      )}
      <span className={styles.dateString}>
        {props.date.getDate()}
      </span>
    </div>
  );
}

const BlockMarkers = (props: IBlockMarkerProps) => {
  if (props.groups && props.groups.length > 0) {
    const groupsWithCurrentRanges = props.groups.map(
      group => ({
        ...group,
        events: group.events.filter(eventGroup =>
          eventGroup.start.getTime() <= props.date.getTime() &&
          eventGroup.end.getTime() >= props.date.getTime()
      )})
    );
    const groupsWithPriorStarts = groupsWithCurrentRanges.filter(
      group => group.events.filter(
        eventGroup => eventGroup.start.getTime() < props.date.getTime()
      ).length > 0
    );
    const groupsWithFollowingEnds = groupsWithCurrentRanges.filter(
      group => group.events.filter(
        eventGroup => eventGroup.end.getTime() > props.date.getTime()
      ).length > 0
    );
    const currentColor = getGroupColor(props.groups);
    const priorColor = groupsWithPriorStarts.length > 0
      ? getGroupColor(groupsWithPriorStarts)
      : 'transparent';
    const nextColor = groupsWithFollowingEnds.length > 0
      ? getGroupColor(groupsWithFollowingEnds)
      : 'transparent';
    return (
      <div className={styles.blockMarker}>
        <div
          className={styles.priorDayIndicator}
          style={{ backgroundColor: priorColor }}
        />
        <div
          className={styles.nextDayIndicator}
          style={{ backgroundColor: nextColor }}
        />
        <div
          className={styles.currentDayIndicator}
          style={{ backgroundColor: currentColor }}
        />
      </div>
    )
  } else {
    return null;
  }
}

const LineMarkers = (props: ILineMarkerProps) => {
  return props.groups ? (
    <div className={styles.groupLines}>
      {props.groups.map(group => (
        <div
          key={group.color}
          className={classnames(
            styles.groupLineMarker,
            group.color === TColor.blue && styles.blue,
            group.color === TColor.orange && styles.orange,
            group.color === TColor.turquoise && styles.turquoise,
            group.color === TColor.purple && styles.purple,
            group.color === TColor.yellow && styles.yellow,
            group.events.filter(event => event.start.getTime() === props.date.getTime()).length > 0 && styles.rangeStart,
            group.events.filter(event => event.end.getTime() === props.date.getTime()).length > 0 && styles.rangeEnd
          )}
        />
      ))}
    </div>
  ) : null
}

export const WeekMarker = (props: IWeekMarkerProps) => (
  <div
    className={classnames(
      styles.date,
      styles.dimmed,
      styles.weekMarker
    )}
    style={{
      gridRowStart: props.week
    }}
  >
    {props.week}
  </div>
);
