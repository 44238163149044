import { IEvent, IEventGroup } from '../../types/event';
import { TEventDisplay } from '../day/types';

export enum RangeMode {
  ADD,
  SUBTRACT
}

/**
 * An array of numbers representing a date, in order year, month, date
 */
export type TSimpleDate = [number, number, number];
/**
 * An array of TSimpleDate arrays indicating a start and end date
 */
export type TSimpleDateRange = [TSimpleDate, TSimpleDate];

export interface ISimpleEventGroup {
  /** The index of the color value associated with this group */
  color: number;
  /** The title string of group */
  title: string;
  /** An array of date ranges this group takes place on */
  events: TSimpleDateRange[];
}

export interface ICalendarState {
  /**
   * Name of the calendar
   */
  name: string;
  /**
   * An array of EventGroup objects
   */
  events: IEventGroup[];
  /**
   * The first month to display in the calendar, represented by day
   */
  startMonth: Date;
  /**
   * Number of months to display in view
   */
  range: number;
  /**
   * Zero-Index of event that is currently active from the `events` prop
   */
  activeEvent: number;
  /**
   * Event object of the newly selected range
   */
  newEventRange: IEvent | null;
  /**
   * Date that a new event drag was started from
   */
  dragStartDate: Date | null;
  /**
   * Determines if a selected range should add or subtract
   */
  rangeMode: RangeMode;
  /**
   * Determines if settings panel is open
   */
  showSettings: boolean;
  /**
   * Determines if the share modal should be shown
   */
  showShare: boolean;
  /**
   * Determines if mobile event group dropdown should be visible
   */
  showEventGroupDropdown: boolean;
  /**
   * Determines if the title change dialog should be rendered
   */
  showTitleDialog: boolean;
  /**
   * Display options
   */
  settings: {
    /**
     * Determines the start month displayed on the calendar
     */
    month: number | null;
    /**
     * Determines the year displayed on the calendar
     */
    year: number;
    /**
     * Determines if weekends should be calculated in day counts
     */
    countWeekends: boolean;
    /**
     * Determines if week numbers should be rendered
     */
    showWeekNumbers: boolean;
    /**
     * Determines if today should be highlighted
     */
    indicateToday: boolean;
    /**
     * Determines how the events should display on the calendar, defaults to blocks
     */
    displayType: TEventDisplay;
  };
  /**
   * Zero-Index of event that is currently set to be editable
   */
  editableEvent: number | null;
}

export interface IAddGroupButtonProps {
  onClick: () => void;
}

export interface IHashURL {
  events: ISimpleEventGroup[];
  startMonth: TSimpleDate;
  countWeekends: boolean;
  indicateToday: boolean;
  displayType: TEventDisplay;
}
