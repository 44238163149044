import { IEventGroup } from '../../types/event';

export enum TEventDisplay {
  Blocks,
  Lines
}

export interface IDateProps {
  /**
   * The date object representing this day, at midnight
   */
  date: Date
  /**
   * Determines if the date falls within the month, defaults to false
   */
  outsideMonth?: boolean;
  /**
   * States if date falls in within the new selection, defaults to false
   */
  inSelection?: boolean;
  /**
   * Array of groups that happen on this day
   */
  selectedGroups?: IEventGroup[];
  /**
   * Determines if today should be marked, defaults to true
   */
  markToday?: boolean;
  /**
   * Determines what the display type for events should look like, defaults to blocks
   */
  displayType?: TEventDisplay
  /**
   * Determines if count weekends checked
   */
  countWeekends?: boolean;
}

export interface ILineMarkerProps {
  date: Date;
  groups?: IEventGroup[];
}

export interface IBlockMarkerProps
  extends ILineMarkerProps {}

export interface IWeekMarkerProps {
  /**
   * Week of month, integer
   */
  week: number;
}