import {
  IHashURL,
  TSimpleDate,
  ISimpleEventGroup,
  TSimpleDateRange
} from '../components/calendar/types';
import { TEventDisplay } from '../components/day/types';

// These functions aim to compress/decompress and serialize/deserialize
// the date ranges we're storing in the URL hash state. The goal is to
// significantly reduce the size of the hash string.
//
// Essentially compression works like this:
// Dates are stored as 3-element tuples, like:
//    [year, month, day]: [number, number, number]
// To compress, we keep track of the most recently processed date.
//    - If the year has not changed, remove the year from the date array.
//    - If the year and the month have not changed, remove both from the date array.
//    - If both have changed, the date is uncompressed.
// When expanding, we use the same conventions to restore the dates.

const START_MONTH = 's';
const EVENTS = 'e';
const COUNT_WEEKENDS = 'c';
const INDICATE_TODAY = 'i';
const DISPLAY_TYPE = 'd';

const serializeEvents = (events: ISimpleEventGroup[]) => {
  return events
    .map(({ color, title, events }) => {
      return `[[[${color},"${encodeURIComponent(title)}"]${JSON.stringify(
        compress(events)
      ).replace(/\],\[/g, '][')}]]`;
    })
    .join('');
};

export const serialize = ({ startMonth, events, countWeekends, indicateToday, displayType }: IHashURL) => {
  return `${START_MONTH}=${JSON.stringify(startMonth)}${
    events.length ? `${EVENTS}=[` : ''
  }${events.length ? serializeEvents(events) : ''}${events.length ? ']' : ''}
  ${COUNT_WEEKENDS}=${countWeekends}
  ${INDICATE_TODAY}=${indicateToday}
  ${DISPLAY_TYPE}=${displayType}`;
};

type EventsArray = [[[string, string], [CompressedDate, CompressedDate][]]];
export const deserialize = (hash: string): IHashURL => {
  const startMonthRe = /s=(\[[\d,]+\])/;
  const eventsRe = /e=(\[.+\])/;
  const countWeekends = /c=(\true|false)/;
  const indicateToday = /i=(\true|false)/;
  const displayType = /d=(0|1)/;
  let parsedCountWeekends = true;
  let parsedIndicateToday = true;
  let parsedDisplayType = TEventDisplay.Blocks;
  if (startMonthRe.test(hash) && eventsRe.test(hash)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_match, startMonth] = hash.match(startMonthRe) as RegExpMatchArray;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_eMatch, events] = hash.match(eventsRe) as RegExpMatchArray;
    const parsedEvents = JSON.parse(events.replace(/\]\[/g, '],[')).map(
      ([[[color, title], events]]: EventsArray) => {
        return {
          color,
          title: decodeURIComponent(title),
          events: expand(events)
        };
      }
    );
    if (countWeekends.test(hash)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const matchCountWeekends = hash.match(countWeekends) as RegExpMatchArray;
      parsedCountWeekends = matchCountWeekends[1] === 'true' ? true : false;
    }
    if (indicateToday.test(hash)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const matchIndicateToday = hash.match(indicateToday) as RegExpMatchArray;
      parsedIndicateToday = matchIndicateToday[1] === 'true' ? true : false;
    }
    if (displayType.test(hash)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const matchDisplayType = hash.match(displayType) as RegExpMatchArray;
      parsedDisplayType = matchDisplayType[1] === '0' ? TEventDisplay.Blocks : TEventDisplay.Lines;
    }

    return {
      events: parsedEvents,
      startMonth: JSON.parse(startMonth),
      countWeekends: parsedCountWeekends,
      indicateToday: parsedIndicateToday,
      displayType: parsedDisplayType

    };
  }
  return {
    events: [],
    startMonth: [0, 1, 2020],
    countWeekends: true,
      indicateToday: true,
      displayType: TEventDisplay.Blocks
  };
};

export type CompressedDate =
  | [number]
  | [number, number]
  | [number, number, number];

type Accumulator = [
  number | null,
  number | null,
  [CompressedDate, CompressedDate][] | []
];

const shrink = (
  [year, month, day]: TSimpleDate,
  prevYear: number | undefined,
  prevMonth: number | undefined
): [CompressedDate, number, number] => {
  if (prevYear === year) {
    if (prevMonth === month) {
      return [[day], year, month];
    } else {
      return [[month, day], year, month];
    }
  } else {
    return [[year, month, day], year, month];
  }
};

export const compress = (ranges: TSimpleDateRange[]) => {
  const compressedRanges = ranges.reduce(
    ([prevYear, prevMonth, ranges]: any, [date1, date2]) => {
      const [newDate1, newYear, newMonth] = shrink(date1, prevYear, prevMonth);
      const [newDate2, finalYear, finalMonth] = shrink(
        date2,
        newYear,
        newMonth
      );
      return [finalYear, finalMonth, [...ranges, [newDate1, newDate2]]];
    },
    [null, null, []] as Accumulator
  );
  return compressedRanges[2];
};

const grow = (
  date: CompressedDate,
  prevYear: number | null,
  prevMonth: number | null
): [TSimpleDate, number, number] => {
  if (date.length === 1 && prevYear !== null && prevMonth !== null) {
    return [[prevYear, prevMonth, date[0]], prevYear, prevMonth];
  } else if (date.length === 2 && prevYear !== null) {
    return [[prevYear, date[0], date[1]], prevYear, date[0]];
  } else if (date.length === 3) {
    return [[date[0], date[1], date[2]], date[0], date[1]];
  } else {
    throw new Error('Bad date compression');
  }
};

export const expand = (ranges: [CompressedDate, CompressedDate][]) => {
  const expandedRanges = ranges.reduce(
    ([prevYear, prevMonth, ranges]: any, [date1, date2]) => {
      const [newDate1, newYear, newMonth] = grow(date1, prevYear, prevMonth);
      const [newDate2, finalYear, finalMonth] = grow(date2, newYear, newMonth);
      return [finalYear, finalMonth, [...ranges, [newDate1, newDate2]]];
    },
    [null, null, []] as any
  );
  return expandedRanges[2];
};
