import React from 'react';
import classnames from 'classnames';
import { Day, WeekMarker } from '../day';
import { TEventDisplay } from '../day/types';
import { IMonthProps } from './types';
import { IEventGroup } from '../../types/event';
import { months } from '../../constants/months-english';
import styles from './style.module.css';

const daysInMonth = (year: number, month: number) => new Date(year, month + 1, 0).getDate();
const weeksInMonth = (year: number, month: number) => {
  let numWeeks = 1;
  const lastDayInMonth = new Date(year, month + 1, 0);
  for (let i = 1; i <= lastDayInMonth.getDate(); i++) {
    const dayOfWeek = new Date(year, month, i).getDay();
    if (dayOfWeek === 0 && i > 1) {
      numWeeks++;
    }
  }
  return numWeeks;
};

const Month = (props: IMonthProps) => {
  const displayType = props.displayType || TEventDisplay.Blocks;
  const showYear: boolean = props.showYear || false;
  const numberOfDays = daysInMonth(props.year, props.month);
  const days: Date[] = [];
  for (let i = 0; i < numberOfDays; i++) {
    days.push(new Date(props.year, props.month, i + 1));
  }
  const numWeeks = weeksInMonth(props.year, props.month);
  const showWeekNumbers = props.showWeekNumbers !== undefined
    ? props.showWeekNumbers
    : true;
  const weekMarkers = [];
  if (showWeekNumbers) {
    for (let i = 0; i < numWeeks; i++) {
      weekMarkers.push(<WeekMarker key={`week-${i + 1}`} week={i + 1} />);
    }
  }
  return (
    <div className={styles.container}>
      <div
        className={
          classnames(
            styles.monthView,
            styles.monthHeader,
            !props.showWeekNumbers && styles.noWeekMarkers
          )
        }
      >
        <h3 className={styles.monthTitle}>
          <span className={styles.shortTitle}>{months[props.month].short}</span>
          <span className={styles.longTitle}>{months[props.month].long}</span>
          {showYear && (<span className={styles.year}> {props.year}</span>)}
        </h3>
        <div style={{ gridColumnStart: 2 }}>Sun</div>
        <div style={{ gridColumnStart: 3 }}>Mon</div>
        <div style={{ gridColumnStart: 4 }}>Tue</div>
        <div style={{ gridColumnStart: 5 }}>Wed</div>
        <div style={{ gridColumnStart: 6 }}>Thu</div>
        <div style={{ gridColumnStart: 7 }}>Fri</div>
        <div style={{ gridColumnStart: 8 }}>Sat</div>
      </div>
      <div
        className={
          classnames(
            styles.monthView,
            !props.showWeekNumbers && styles.noWeekMarkers
          )
        }
      >
        {showWeekNumbers && weekMarkers}
        {days.map((day, index) => {
          const inNewRange = props.newEventRange &&
            day >= props.newEventRange.start &&
            day <= props.newEventRange.end;
          const selectedGroups: IEventGroup[] = [];
          if (props.events) {
            props.events.forEach(eventGroup => {
              for (let i = 0; i < eventGroup.events.length; i++) {
                const event = eventGroup.events[i];
                const eventInRange =
                  day >= event.start &&
                  day <= event.end;
                if (eventInRange) {
                  selectedGroups.push(eventGroup);
                  break;
                }
              }
            });
          }
          return (
            <Day
              markToday={props.indicateToday}
              countWeekends={props.countWeekends}
              inSelection={inNewRange}
              selectedGroups={selectedGroups}
              date={day}
              key={`${months[props.month].short}-${index}`}
              displayType={displayType}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Month;