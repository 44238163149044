import React from 'react';
import ReactDOM from 'react-dom';
import { init } from '@sentry/browser';

import './index.css';
import Calendar from './components/calendar';
import * as serviceWorker from './serviceWorker';

init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});


ReactDOM.render(<Calendar />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome from automatically showing the prompt on mobile
    // it can still be installed on desktop
    e.preventDefault();
});