import React from 'react';
import classnames from 'classnames';
import gaEvent from '../../utils/ga-events';
import styles from './style.module.css';
import { TEventDisplay } from '../day/types';
import { ISettingsProps } from './types';

const Settings = (props: ISettingsProps) => {
  const handleSetCountWeekends = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onSetCountWeekends(e.target.checked);
  
  const handleSetIndicateToday = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onSetIndicateToday(e.target.checked);
  
  const handleSetDisplayLines = () => {
    props.onSetDisplayType(TEventDisplay.Lines);
    gaEvent({
      category: 'Display Style',
      action: 'click',
      label: 'Display as Lines'
    });
  };
  
  const handleSetDisplayBlocks = () => {
    props.onSetDisplayType(TEventDisplay.Blocks);
    gaEvent({
      category: 'Display Style',
      action: 'click',
      label: 'Display as Blocks'
    });
  };
  
  const handleSetMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.length > 2 && !value.startsWith('0')) {
      value = value.substr(0, 2);
    }
    props.onSetMonth(value);
  };
  const handleSetYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    // don't allow > 4 chars in year
    if (e.currentTarget.value.length > 4) {
      return;
    }
    props.onSetYear(Number(e.currentTarget.value));
  };

  const displayMonthYear = () => {
    props.displayMonthYear(props.month !== null ? props.month : 0, props.year);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.displayMonthYear(
      props.month !== null ? props.month : 0,
      props.year
    );
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = e.key.toString();
    // don't allow user to enter e
    if (keyPressed === 'e' || keyPressed === '.') {
      e.preventDefault();
      return;
    }
    // don't blur if invalid month
    if (props.month !== null && (props.month > 12 || props.month < 1)) {
      return;
    }
    // don't blur if invalid year
    if (props.year !== null && props.year.toString().length !== 4) {
      return;
    }
    if (keyPressed === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const handleFocus = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }

  return (
    <div
      className={classnames(
        styles.optionsGroupContainer,
        props.twoColumnLayout && styles.twoColumns
      )}
    >
      <section>
        <h3>Calendar</h3>
        <form className={styles.dayYearOptions} onSubmit={handleSubmit}>
          <span className={classnames(styles.calendarInputLabel)}>
            Start on
          </span>
          <input
            className={classnames(
              styles.calendarInputLeft,
              props.month !== null && (props.month > 12 || props.month < 1) && styles.colorRed
            )}
            type="number"
            pattern="[0-9]*"
            name="event-display"
            value={
              props.month !== null
                ? props.month.toString().length === 1 && props.month !== 0
                ? ('0' + props.month).slice(-2)
                : `${props.month}`
                  : ''
            }
            placeholder="MM"
            onChange={handleSetMonth}
            onBlur={displayMonthYear}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
          />
          <input
            className={classnames(
              styles.calendarInputRight,
              props.year.toString().length < 4 && styles.colorRed
            )}
            type="number"
            pattern="[0-9]*"
            name="event-display"
            placeholder="YYYY"
            value={props.year === 0 ? '' : props.year}
            onChange={handleSetYear}
            onBlur={displayMonthYear}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
          />
          <input type="submit" value="submit" style={{display: "none"}} />
        </form>

        <label className={styles.checkOption}>
          <input
            onChange={handleSetCountWeekends}
            checked={props.countWeekends}
            type="checkbox"
          />
          Count weekends
        </label>
        <label className={styles.checkOption}>
          <input
            onChange={handleSetIndicateToday}
            checked={props.indicateToday}
            type="checkbox"
          />
          Indicate today
        </label>
      </section>
      <section>
        <h3>Marked Dates</h3>
        <div className={styles.dayMarkerOptions}>
          <label className={styles.dayMarkerOption}>
            <input
              className={classnames(
                styles.markerOptionInput,
                props.displayType === TEventDisplay.Blocks && styles.checked
              )}
              type="radio"
              name="event-display"
              value={TEventDisplay.Blocks}
              checked={props.displayType === TEventDisplay.Blocks}
              onChange={handleSetDisplayBlocks}
            />
            <span className={styles.markerOptionText}>Blocks</span>
          </label>
          <label className={styles.dayMarkerOption}>
            <input
              className={classnames(
                styles.markerOptionInput,
                props.displayType === TEventDisplay.Lines && styles.checked
              )}
              type="radio"
              name="event-display"
              value={TEventDisplay.Lines}
              checked={props.displayType === TEventDisplay.Lines}
              onChange={handleSetDisplayLines}
            />
            <span className={styles.markerOptionText}>Lines</span>
          </label>
        </div>
      </section>
    </div>
  );
};

export default Settings;
