import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import GroupIndicator from '../group-indicator';
import { calcDays } from '../../utils/calc-days';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import removeIcon from '../../assets/remove-icon.svg';
import enterIcon from '../../assets/enter-icon.svg';
import styles from './style.module.css';
import {
  IEventInputProps,
  IEventInputState,
  IEditButtonProps,
  IRemoveButtonProps
} from './types';

const EditBtn = (props: IEditButtonProps) => (
  <button className={styles.editBtn} onClick={props.onClick}>
    <EditIcon />
  </button>
);

const RemoveBtn = (props: IRemoveButtonProps) => (
  <button className={styles.removeBtn} onMouseDown={props.onMouseDown}>
    <img className={styles.removeIcon} alt="Remove calendar" src={removeIcon} />
  </button>
);

export default class EventInput extends Component<
  IEventInputProps,
  IEventInputState
> {
  private inputRef = createRef<HTMLInputElement>();
  state: IEventInputState = {
    showEditBtn: false
  };

  private setActive = () => this.props.setActive(this.props.index);

  private changeTitle = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onTitleChange(event.currentTarget.value, this.props.index);

  private handleRequestChange = (event: React.MouseEvent) => {
    this.props.onRequestEdit &&
      this.props.onRequestEdit(this.props.index, this.props.mobile!!);
    event.stopPropagation();
  };

  private handleRequestRemove = () =>
    this.props.onRemove && this.props.onRemove(this.props.index);

  private onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      this.inputRef.current && this.inputRef.current.blur();
      this.props.exitEditMode && this.props.exitEditMode();
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const nextIndex = this.props.index + 1;
    if(event.key === 'Tab') {
      this.props.onTabClickNavigation &&
        this.props.onTabClickNavigation(nextIndex, event);
    }
  };

  private onBlur = () => this.props.exitEditMode && this.props.exitEditMode();

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.currentTarget.select();

  private showEditBtn = () => this.setState({ showEditBtn: true });

  private hideEditBtn = () => this.setState({ showEditBtn: false });

  render() {
    const numDays = calcDays(this.props.event.events, this.props.countWeekends);

    return (
      <div
        className={classnames(
          styles.eventGroupOptionContainer,
          this.props.mobile && styles.mobile
        )}
      >
        <div
          className={classnames(
            styles.eventGroupOption,
            this.props.activeEvent && styles.active
          )}
          onClick={this.setActive}
          onMouseEnter={this.showEditBtn}
          onMouseLeave={this.hideEditBtn}
        >
          {this.props.editMode && !this.props.mobile ? (
            <>
              <RemoveBtn onMouseDown={this.handleRequestRemove} />
              <input
                ref={this.inputRef}
                autoFocus
                className={styles.eventTitleInput}
                type="name"
                onChange={this.changeTitle}
                value={this.props.event.title}
                onKeyPress={this.onKeyPress}
                onKeyDown={this.onKeyDown}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
              />
              <img
                className={styles.enterIcon}
                alt="Save changes"
                src={enterIcon}
              />
            </>
          ) : (
            <>
              <GroupIndicator
                active={this.props.activeEvent}
                color={this.props.event.color}
              />
              <b className={styles.eventTitle}>{this.props.event.title}</b>
              {this.state.showEditBtn && !this.props.mobile ? (
                <EditBtn onClick={this.handleRequestChange} />
              ) : (
                <div className={styles.daysCounter}>
                  {numDays} {numDays === 1 ? 'day' : 'days'}
                </div>
              )}
            </>
          )}
        </div>
        {this.props.mobile && <EditBtn onClick={this.handleRequestChange} />}
      </div>
    );
  }
}
