interface iGAEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
};

export default ({
  category,
  action,
  label,
  value,
}: iGAEvent) => {
  if ((window as any)?.ga) {
    (window as any).ga(
      'send',
      'event',
      category,
      action,
      label,
      value
    );
  }
};
