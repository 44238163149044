import { IEvent } from "../types/event";

const isWeekend = (d: Date): boolean => d.getDay() === 0 || d.getDay() === 6;

const addOneDay = (d: Date): Date =>
  new Date(new Date(d).setDate(d.getDate() + 1));
const subtractOneDay = (d: Date): Date =>
  new Date(new Date(d).setDate(d.getDate() - 1));

const getStartDay = (d: Date, end: Date): Date | false => {
  if (!isWeekend(d)) {
    return d;
  }
  let newStartDay = addOneDay(d);
  while (newStartDay.getTime() <= end.getTime()) {
    if (!isWeekend(newStartDay)) {
      return newStartDay;
    }
    newStartDay = addOneDay(newStartDay);
  }
  return false;
};

const splitOnWeekends = (ranges: IEvent[]) =>
  ranges.reduce(
    (acc: IEvent[], range: IEvent) => {
      const { start, end } = range;
      let today = getStartDay(start, end);
      // if there is no start day b/w the start and end dates, exclude this range
      if (!today) return acc;
      const newRanges: IEvent[] = [{ start: today, end: today }];
      while (today.getTime() <= end.getTime() && newRanges.length > 0) {
        if (isWeekend(today)) {
          const range = newRanges.pop() as IEvent;
          newRanges.push({ start: range.start, end: subtractOneDay(today) });
          today = getStartDay(today, end);
          if (!today) break;
          newRanges.push({ start: today, end: today });
        } else {
          today = addOneDay(today);
          if (today.getTime() === end.getTime()) {
            const range = newRanges.pop() as IEvent;
            newRanges.push({ ...range, end });
          }
        }
      }
      return [...acc, ...newRanges];
    },
    [] as IEvent[]
  );

export default splitOnWeekends;
